import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: { translation: require('../assets/translations/en.json') },
  ro: { translation: require('../assets/translations/ro.json') },
  hu: { translation: require('../assets/translations/hu.json') },
};

export const setI18nConfig = () => {
  i18n
    .use(detector)
    .use(initReactI18next)
    .init({
      resources,
      fallbackLng: 'en',
    });
}

const t = (val: string) => i18n.t(val);

export { t };

export default i18n;
