import React, { Component } from 'react';
import { Navbar, Nav, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import CSS from 'csstype';

interface HeaderProps {
  lang?: string;
  onChangeLanguage: (lang: string) => void;
}

interface HeaderState {
  isOpenLanguage: boolean;
}

class Header extends Component<HeaderProps, HeaderState> {
  state = {
    isOpenLanguage: false
  }

  toggle = () => {
    this.setState(prevState => ({
      isOpenLanguage: !prevState.isOpenLanguage
    }));
  }

  render() {
    return (
      <Navbar color='transparent' expand='lg' style={styles.navbar}>
        <Nav className='mr-auto' navbar />
        <Dropdown inNavbar isOpen={this.state.isOpenLanguage} toggle={this.toggle}>
          <DropdownToggle caret>
             Language
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem className='dropdown-item' onClick={() => this.props.onChangeLanguage('en')}>
              <div className='dropdown-item'>
                <span className='flag-icon flag-icon-us' /> English
              </div>
            </DropdownItem>
            <DropdownItem onClick={() => this.props.onChangeLanguage('ro')}>
              <div className='dropdown-item'>
                <span className='flag-icon flag-icon-ro' /> Română
              </div>
            </DropdownItem>
            <DropdownItem onClick={() => this.props.onChangeLanguage('hu')}>
              <div className='dropdown-item'>
                <span className='flag-icon flag-icon-hu' /> Magyar
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Navbar>
    );
  }
}

export { Header };

const styles: { [key: string]: CSS.Properties } = {
  navbar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  }
};
