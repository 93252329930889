import { EMAIL } from './email.actions';
import { EmailState } from './email.types';

const INITIAL_STATE: EmailState = {
  loadingSendEmail: false,
  hasErrorSendEmail: false,
  errorMessageSendEmail: '',
};

export default (state = INITIAL_STATE, action: any): EmailState => {
  switch (action.type) {
    // sendEmail
    case EMAIL.START_LOADING_SENDEMAIL:
      return { ...state, loadingSendEmail: true };
    case EMAIL.FINISH_LOADING_SENDEMAIL:
      return { ...state, loadingSendEmail: false };
    case EMAIL.SET_SENDEMAIL_ERROR:
      return { ...state, hasErrorSendEmail: true, errorMessageSendEmail: action.payload };
    case EMAIL.HIDE_SENDEMAIL_ERROR:
      return { ...state, hasErrorSendEmail: false, errorMessageSendEmail: '' };
    default:
      return state;
  }
};
