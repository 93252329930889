import { createAction } from 'redux-actions';
import firebase from '../../utils/firebase';
import { API } from '../../constants';
import { AppThunk } from '../store';
const { functions } = firebase;

export const EMAIL = {
  // sendEmail
  START_LOADING_SENDEMAIL: 'start_loading_sendEmail_function',
  FINISH_LOADING_SENDEMAIL: 'finish_loading_sendEmail_function',
  SET_SENDEMAIL_ERROR: 'set_sendEmail_function_error',
  HIDE_SENDEMAIL_ERROR: 'hide_sendEmail_function_error',
}

// sendEmail - api function
export const startLoadingSendEmail = createAction(EMAIL.START_LOADING_SENDEMAIL);
export const finishLoadingSendEmail = createAction(EMAIL.FINISH_LOADING_SENDEMAIL);
export const setSendEmailError = createAction(EMAIL.SET_SENDEMAIL_ERROR);
export const hideSendEmailError = createAction(EMAIL.HIDE_SENDEMAIL_ERROR);

export function sendEmail(
  { name, email, message }: { name: string, email: string, message: string },
  onSuccess: () => void = () => {},
  onError: (errMessage: string) => void = () => {},
): AppThunk<Promise<void>> {
  return (dispatch: any) => {
    dispatch(startLoadingSendEmail());
    const httpsCallable = functions().httpsCallable(API.SEND_EMAIL);
    return httpsCallable({ name, email, message })
      .then(() => {
        dispatch(finishLoadingSendEmail());
        onSuccess();
      })
      .catch(err => {
        dispatch(finishLoadingSendEmail());
        dispatch(setSendEmailError(err.message));
        onError(err.message);
      });
  };
}
