import React from 'react';
import { MathJaxContext, EditArticleForm } from '../common';
import { ArticleType } from '../types';

interface EditArticleProps {
  article: ArticleType;
  setArticleId: (id: string) => void;
  setArticleTitle: (title: string) => void;
  setArticleContent: (content: string) => void;
  updateArticle: () => void;
  loadingUpdate: boolean;
  password: string;
  setPassword: (password: string) => void;
  lang: string;
  setLang: (lang: string) => void;
  preview: boolean;
  togglePreview: () => void;
}

const EditArticle: React.FC<EditArticleProps> = props => (
  <div>
    <div className='row'>
      {/* Preview */}
      <div className='col border-right'>
        <div className='mx-3 mt-2'>
          <div className='d-flex justify-content-center'>
            <MathJaxContext html={props.article.title} />
          </div>
          <MathJaxContext html={props.article.content} />
        </div>
      </div>
      {/* Edit */}
      {!props.preview &&
        <div className='col border-left mt-2 mr-3'>
          <EditArticleForm
            id={props.article.id}
            setId={props.setArticleId}
            title={props.article.title}
            setTitle={props.setArticleTitle}
            content={props.article.content}
            setContent={props.setArticleContent}
            lang={props.lang}
            setLang={props.setLang}
            password={props.password}
            setPassword={props.setPassword}
            onSubmit={props.updateArticle}
            submitting={props.loadingUpdate}
            submitTitle='Update'
            idDisabled
          />
        </div>
      }
    </div>
    {/* Preview / Edit button */}
    <div className='d-flex justify-content-center'>
      <button className='btn btn-sm btn-outline-primary my-4' onClick={props.togglePreview}>
        {props.preview ? 'Edit' : 'Preview'}
      </button>
    </div>
  </div>
);

export default EditArticle;
