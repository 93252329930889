import React, { Component, ChangeEvent } from 'react';
import { t } from '../utils/i18n';
import { connect, ConnectedProps } from 'react-redux';
import {
  // sendEmail - function
  sendEmail,
  hideSendEmailError,
} from '../redux/actions';
import Main from './Main';
import { RootState } from '../redux/store';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface MainPageProps extends PropsFromRedux {}

interface MainPageState {
  name: string;
  email: string;
  message: string;
}

class MainPage extends Component<MainPageProps, MainPageState> {
  state = {
    name: '',
    email: '',
    message: '',
  };

  onPressSendEmail = () => {
    const { name, email, message } = this.state;
    const onSuccess = () => this.setState({ name: '', email: '', message: '' });
    const onError = (errMsg: string) => alert(t('unable to send') + '\n' + errMsg);
    this.props.sendEmail({ name, email, message }, onSuccess, onError);
  };

  render() {
    return (
      <Main
        name={this.state.name}
        email={this.state.email}
        message={this.state.message}
        onChangeName={(e: ChangeEvent<HTMLInputElement>) => this.setState({ name: e.target.value })}
        onChangeEmail={(e: ChangeEvent<HTMLInputElement>) => this.setState({ email: e.target.value })}
        onChangeMessage={(e: ChangeEvent<HTMLTextAreaElement>) => this.setState({ message: e.target.value })}
        onPressSendEmail={this.onPressSendEmail}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const { loadingSendEmail, hasErrorSendEmail, errorMessageSendEmail } = state.email;
  return { loadingSendEmail, hasErrorSendEmail, errorMessageSendEmail };
};

const mapDispatch = {
  // sendEmail - entity
  sendEmail,
  hideSendEmailError,
};

const connector = connect(
  mapStateToProps,
  mapDispatch,
);

export default connector(MainPage);
