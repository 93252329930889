import React from 'react';

interface EditArticleFormProps {
  id: string;
  setId: (id: string) => void;
  title: string;
  setTitle: (title: string) => void;
  content: string;
  setContent: (content: string) => void;
  lang: string;
  setLang: (lang: string) => void;
  password: string;
  setPassword: (password: string) => void;
  onSubmit: () => void;
  submitting: boolean;
  submitTitle: string;
  idDisabled?: boolean;
}

const EditArticleForm: React.FC<EditArticleFormProps> = props => (
  <>
    {/* title */}
    <div className='form-group'>
      <label htmlFor='title'>Title</label>
      <textarea
        className='form-control form-control-sm'
        id='title'
        placeholder='Title of article'
        rows={2}
        value={props.title}
        onChange={e => props.setTitle(e.target.value)}
      />
    </div>
    {/* content */}
    <div className='form-group'>
      <label htmlFor='content'>Content</label>
      <textarea
        className='form-control form-control-sm'
        id='content'
        placeholder='Content of article'
        rows={20}
        value={props.content}
        onChange={e => props.setContent(e.target.value)}
      />
    </div>
    {/* article id */}
    <div className='form-group'>
      <label htmlFor='id'>Id</label>
      <input
        className='form-control form-control-sm'
        type='text'
        placeholder='Id of article (leave empty to generate new id)'
        disabled={props.idDisabled}
        value={props.id}
        onChange={e => props.setId(e.target.value)}
      />
    </div>
    {/* language selector */}
    <div className='form-group'>
      <label htmlFor='langSelect'>Language</label>
      <select
        className='form-control form-control-sm'
        id='langSelect'
        value={props.lang}
        onChange={e => props.setLang(e.target.value)}
      >
        <option>en</option>
        <option>ro</option>
        <option>hu</option>
      </select>
    </div>
    {/* password */}
    <div className='form-group'>
      <label htmlFor='id'>Password</label>
      <input
        className='form-control form-control-sm'
        type='password'
        placeholder='Password to create a new article'
        value={props.password}
        onChange={e => props.setPassword(e.target.value)}
      />
    </div>
    {/* action button */}
    <button
      className='btn btn-sm btn-outline-success pull-right mt-4'
      disabled={props.submitting}
      onClick={props.onSubmit}
    >
      {props.submitTitle}
    </button>
  </>
);

EditArticleForm.defaultProps = {
  idDisabled: false,
}

export { EditArticleForm };
