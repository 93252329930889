import { ARTICLE } from './article.actions';
import { ArticleState } from './article.types';

const INITIAL_STATE: ArticleState = {
  // getArticle
  loadingGetArticle: false,
  hasErrorGetArticle: false,
  errorMessageGetArticle: '',
  // createArticle
  loadingCreateArticle: false,
  hasErrorCreateArticle: false,
  errorMessageCreateArticle: '',
  // updateArticle
  loadingUpdateArticle: false,
  hasErrorUpdateArticle: false,
  errorMessageUpdateArticle: '',
};

export default (state = INITIAL_STATE, action: any): ArticleState => {
  switch (action.type) {
    // getArticle
    case ARTICLE.START_LOADING_GETARTICLE:
      return { ...state, loadingGetArticle: true };
    case ARTICLE.FINISH_LOADING_GETARTICLE:
      return { ...state, loadingGetArticle: false };
    case ARTICLE.SET_GETARTICLE_ERROR:
      return { ...state, hasErrorGetArticle: true, errorMessageGetArticle: action.payload };
    case ARTICLE.HIDE_GETARTICLE_ERROR:
      return { ...state, hasErrorGetArticle: false, errorMessageGetArticle: '' };
    // createArticle
    case ARTICLE.START_LOADING_CREATEARTICLE:
      return { ...state, loadingCreateArticle: true };
    case ARTICLE.FINISH_LOADING_CREATEARTICLE:
      return { ...state, loadingCreateArticle: false };
    case ARTICLE.SET_CREATEARTICLE_ERROR:
      return { ...state, hasErrorCreateArticle: true, errorMessageCreateArticle: action.payload };
    case ARTICLE.HIDE_CREATEARTICLE_ERROR:
      return { ...state, hasErrorCreateArticle: false, errorMessageCreateArticle: '' };
    // updateArticle
    case ARTICLE.START_LOADING_UPDATEARTICLE:
      return { ...state, loadingUpdateArticle: true };
    case ARTICLE.FINISH_LOADING_UPDATEARTICLE:
      return { ...state, loadingUpdateArticle: false };
    case ARTICLE.SET_UPDATEARTICLE_ERROR:
      return { ...state, hasErrorUpdateArticle: true, errorMessageUpdateArticle: action.payload };
    case ARTICLE.HIDE_UPDATEARTICLE_ERROR:
      return { ...state, hasErrorUpdateArticle: false, errorMessageUpdateArticle: '' };
    default:
      return state;
  }
};
