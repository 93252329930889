// Firebase cloud functions API
export const API = {
  SEND_EMAIL: 'sendEmail',
  CREATE_ARTICLE: 'createArticle',
  UPDATE_ARTICLE: 'updateArticle',
};

// Firebase firestore collections
export const COLLECTION = {
  LANGS: 'langs',
  ARTICLES: 'articles',
};
