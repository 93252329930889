import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import MainPage from './Main/Main.page';
import CreateArticlePage from './CreateArticle/CreateArticle.page';
import EditArticlePage from './EditArticle/EditArticle.page';
import ArticlePage from './Article/Article.page';

interface RouterProps {}

const Router: React.FC<RouterProps> = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path={['/', '/main']} component={MainPage} />
      <Route exact path='/createArticle' component={CreateArticlePage} />
      <Route exact path='/article/:id' component={ArticlePage} />
      <Route exact path='/article/:id/edit' component={EditArticlePage} />
      <Route exact path='/blog'>
        <Redirect to='/article/blog' />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default Router;
