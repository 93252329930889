import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyAYppjIVlHprXtVuxfyD0hFrStSpIeywY0',
  authDomain: 'gotha-guntter-pfa.firebaseapp.com',
  databaseURL: 'https://gotha-guntter-pfa.firebaseio.com',
  projectId: 'gotha-guntter-pfa',
  storageBucket: 'gotha-guntter-pfa.appspot.com',
  messagingSenderId: '768883863012',
  appId: '1:768883863012:web:8bc3a559d911ba38596bf1',
  measurementId: 'G-0HRW1Z8VSX'
};

firebase.initializeApp(config);

export default firebase;
