import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  // getArticle - function
  getArticle,
  hideGetArticleError,
  // updateArticle - function
  updateArticle,
  hideUpdateArticleError,
} from '../redux/actions';
import EditArticle from './EditArticle';
import i18n from '../utils/i18n';
import { ArticleType } from '../types';
import { RootState } from '../redux/store';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface EditArticlePageProps extends PropsFromRedux, RouteComponentProps<{ id: string }> {}

interface EditArticlePageState {
  article: ArticleType;
  password: string;
  lang: string;
  preview: boolean;
}

const INITIAL_STATE: EditArticlePageState = {
  article: {
    id: '',
    title: '',
    content: '',
  },
  password: '',
  lang: 'en',
  preview: false,
}

class EditArticlePage extends Component<EditArticlePageProps, EditArticlePageState> {
  state = INITIAL_STATE;

  componentDidMount() {
    const { id } = this.props.match.params;
    const onSuccess = (article: ArticleType) => this.setState({ article, lang: i18n.language });
    const onError = (errMsg: string) => alert(errMsg);
    this.props.getArticle({ id, lang: i18n.language }, onSuccess, onError);
  }

  updateArticle = () => {
    const { article, lang, password } = this.state;
    const onSuccess = () => {
      i18n.changeLanguage(lang);
      this.setState(INITIAL_STATE, () => window.location.href = `/article/${article.id}`);
    };
    const onError = (errorMsg: string) => {
      alert(errorMsg);
      this.props.hideUpdateArticleError();
    }
    this.props.updateArticle({ article, lang, prevLang: i18n.language, password }, onSuccess, onError);
  }

  render() {
    return (
      <EditArticle
        article={this.state.article}
        setArticleId={(id: string) => this.setState({ article: { ...this.state.article, id } })}
        setArticleTitle={(title: string) => this.setState({ article: { ...this.state.article, title }})}
        setArticleContent={(content: string) => this.setState({ article: { ...this.state.article, content }})}
        updateArticle={this.updateArticle}
        loadingUpdate={this.props.loadingUpdateArticle}
        password={this.state.password}
        setPassword={(password: string) => this.setState({ password })}
        lang={this.state.lang}
        setLang={(lang: string) => this.setState({ lang })}
        preview={this.state.preview}
        togglePreview={() => this.setState(prevState => ({ preview: !prevState.preview }))}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const {
    loadingGetArticle,
    hasErrorGetArticle,
    errorMessageGetArticle,
    loadingUpdateArticle,
    hasErrorUpdateArticle,
    errorMessageUpdateArticle
  } = state.article;
  return {
    loadingGetArticle,
    hasErrorGetArticle,
    errorMessageGetArticle,
    loadingUpdateArticle,
    hasErrorUpdateArticle,
    errorMessageUpdateArticle
  };
};

const mapDispatch = {
  // getArticle
  getArticle,
  hideGetArticleError,
  // updateArticle
  updateArticle,
  hideUpdateArticleError,
};

const connector = connect(
  mapStateToProps,
  mapDispatch,
);

export default connector(EditArticlePage);
