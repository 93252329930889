import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  // getArticle - function
  getArticle,
  hideGetArticleError,
} from '../redux/actions';
import Article from './Article';
import i18n from '../utils/i18n';
import { ArticleType } from '../types';
import { RootState } from '../redux/store';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ArticlePageProps extends PropsFromRedux, RouteComponentProps<{ id: string }> {}

interface ArticlePageState {
  article: ArticleType;
}

class ArticlePage extends Component<ArticlePageProps, ArticlePageState> {
  state = {
    article: {
      id: this.props.match.params.id,
      title: '',
      content: '',
    }
  }

  componentDidMount() {
    const { id } = this.state.article;
    const onSuccess = (article: ArticleType) => this.setState({ article });
    const onError = () => {};
    this.props.getArticle({ id, lang: i18n.language }, onSuccess, onError);
  }

  onChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    window.location.reload();
  }

  render() {
    return (
      <Article
        onChangeLanguage={this.onChangeLanguage}
        loading={this.props.loadingGetArticle}
        hasError={this.props.hasErrorGetArticle}
        errorMessageGetArticle={this.props.errorMessageGetArticle}
        hideError={this.props.hideGetArticleError}
        article={this.state.article}
      />
    )
  }
}

const mapStateToProps = (state: RootState) => {
  const { loadingGetArticle, hasErrorGetArticle, errorMessageGetArticle } = state.article;
  return { loadingGetArticle, hasErrorGetArticle, errorMessageGetArticle };
};

const mapDispatch = {
  // getArticle
  getArticle,
  hideGetArticleError,
};

const connector = connect(
  mapStateToProps,
  mapDispatch,
);

export default connector(ArticlePage);
