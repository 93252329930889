import React from 'react';
import { Header } from './Header';

interface LayoutProps {
  onChangeLanguage: (lang: string) => void;
}

const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <div>
      {/*
      <Head>
        <meta charSet='utf-8' />
        <meta httpEquiv='X-UA-Compatible' content='IE=edge,chrome=1' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />

        <link rel='icon' href='/favicon.ico' />
        <link href='https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800' rel='stylesheet' />
      </Head>
      */}
      <Header onChangeLanguage={props.onChangeLanguage} />
      <div>
        {props.children}
      </div>
    </div>
  );
};

export { Layout };
