import { createAction } from 'redux-actions';
import firebase from '../../utils/firebase';
import { API, COLLECTION } from '../../constants';
import { AppThunk } from '../store';
import { ArticleType } from '../../types';
const { firestore, functions } = firebase;

export const ARTICLE = {
  // getArticle
  START_LOADING_GETARTICLE: 'start_loading_getArticle_function',
  FINISH_LOADING_GETARTICLE: 'finish_loading_getArticle_function',
  SET_GETARTICLE_ERROR: 'set_getArticle_function_error',
  HIDE_GETARTICLE_ERROR: 'hide_getArticle_function_error',
  // createArticle
  START_LOADING_CREATEARTICLE: 'start_loading_createArticle_function',
  FINISH_LOADING_CREATEARTICLE: 'finish_loading_createArticle_function',
  SET_CREATEARTICLE_ERROR: 'set_createArticle_function_error',
  HIDE_CREATEARTICLE_ERROR: 'hide_createArticle_function_error',
  // updateArticle
  START_LOADING_UPDATEARTICLE: 'start_loading_updateArticle_function',
  FINISH_LOADING_UPDATEARTICLE: 'finish_loading_updateArticle_function',
  SET_UPDATEARTICLE_ERROR: 'set_updateArticle_function_error',
  HIDE_UPDATEARTICLE_ERROR: 'hide_updateArticle_function_error',
}

// getArticle - db function
export const startLoadingGetArticle = createAction(ARTICLE.START_LOADING_GETARTICLE);
export const finishLoadingGetArticle = createAction(ARTICLE.FINISH_LOADING_GETARTICLE);
export const setGetArticleError = createAction(ARTICLE.SET_GETARTICLE_ERROR);
export const hideGetArticleError = createAction(ARTICLE.HIDE_GETARTICLE_ERROR);

export function getArticle(
  { id, lang }: { id: string; lang: string },
  onSuccess: (article: ArticleType) => void = () => {},
  onError: (errMessage: string) => void = () => {},
): AppThunk<Promise<void>> {
  return (dispatch: any) => {
    dispatch(startLoadingGetArticle());
    return firestore()
      .collection(COLLECTION.LANGS)
      .doc(lang)
      .collection(COLLECTION.ARTICLES)
      .doc(id)
      .get()
      .then(doc => {
        if (doc.exists) {
          onSuccess({ id, ...doc.data() } as ArticleType);
          dispatch(finishLoadingGetArticle());
        } else {
          throw new Error('Article not found.');
        }
      })
      .catch(err => {
        dispatch(finishLoadingGetArticle());
        dispatch(setGetArticleError(err.message));
        onError(err.message);
      });
  };
}

// createArticle - api function
export const startLoadingCreateArticle = createAction(ARTICLE.START_LOADING_CREATEARTICLE);
export const finishLoadingCreateArticle = createAction(ARTICLE.FINISH_LOADING_CREATEARTICLE);
export const setCreateArticleError = createAction(ARTICLE.SET_CREATEARTICLE_ERROR);
export const hideCreateArticleError = createAction(ARTICLE.HIDE_CREATEARTICLE_ERROR);

export function createArticle(
  { article, lang, password }: { article: ArticleType; lang: string; password: string },
  onSuccess: (id: string) => void = () => {},
  onError: (errMessage: string) => void = () => {},
): AppThunk<Promise<void>> {
  return (dispatch: any) => {
    dispatch(startLoadingCreateArticle());
    const httpsCallable = functions().httpsCallable(API.CREATE_ARTICLE);
    return httpsCallable({ article, lang, password })
      .then(({ data }) => {
        dispatch(finishLoadingCreateArticle());
        onSuccess(data);
      })
      .catch(err => {
        dispatch(finishLoadingCreateArticle());
        dispatch(setCreateArticleError(err.message));
        onError(err.message);
      });
  };
}

// updateArticle - api function
export const startLoadingUpdateArticle = createAction(ARTICLE.START_LOADING_UPDATEARTICLE);
export const finishLoadingUpdateArticle = createAction(ARTICLE.FINISH_LOADING_UPDATEARTICLE);
export const setUpdateArticleError = createAction(ARTICLE.SET_UPDATEARTICLE_ERROR);
export const hideUpdateArticleError = createAction(ARTICLE.HIDE_UPDATEARTICLE_ERROR);

export function updateArticle(
  { article, lang, prevLang, password }: { article: ArticleType; lang: string; prevLang: string; password: string },
  onSuccess: () => void = () => {},
  onError: (errMessage: string) => void = () => {},
): AppThunk<Promise<void>> {
  return (dispatch: any) => {
    dispatch(startLoadingUpdateArticle());
    const httpsCallable = functions().httpsCallable(API.UPDATE_ARTICLE);
    return httpsCallable({ article, lang, prevLang, password })
      .then(({ data }) => {
        dispatch(finishLoadingUpdateArticle());
        onSuccess();
      })
      .catch(err => {
        dispatch(finishLoadingUpdateArticle());
        dispatch(setUpdateArticleError(err.message));
        onError(err.message);
      });
  };
}
