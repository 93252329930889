import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../utils/i18n';
import { Layout } from '../common';
import './Main.css';

interface MainProps {
  name: string;
  email: string;
  message: string;
  onChangeName: (name: ChangeEvent<HTMLInputElement>) => void;
  onChangeEmail: (email: ChangeEvent<HTMLInputElement>) => void;
  onChangeMessage: (message: ChangeEvent<HTMLTextAreaElement>) => void;
  onPressSendEmail: () => void;
};

const Main: React.FC<MainProps> = props => {
  const { t } = useTranslation();
  return (
    <Layout onChangeLanguage={lang => i18n.changeLanguage(lang)}>
      {/*
      <Head>
        <title>Home</title>
        <meta name='description' content='Order professional software presentation page' />
      </Head>
      */}

      <section className='first-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='text-content'>
                <h2>{t('first-section.title')}</h2>
                <div className='line-dec' />
                <span>{t('first-section.subtitle')}</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='second-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-3 col-sm-6'>
              <div className='service-item'>
                <div className='icon'>
                  <img src='img/first-icon.png' alt='' />
                </div>
                <h4>{t('second-section.item-1-title')}</h4>
                <p>{t('second-section.item-1-subtitle')}</p>
              </div>
            </div>
            <div className='col-md-3 col-sm-6'>
              <div className='service-item'>
                <div className='icon'>
                  <img src='img/second-icon.png' alt='' />
                </div>
                <h4>{t('second-section.item-2-title')}</h4>
                <p>{t('second-section.item-2-subtitle')}</p>
              </div>
            </div>
            <div className='col-md-3 col-sm-6'>
              <div className='service-item'>
                <div className='icon'>
                  <img src='img/third-icon.png' alt='' />
                </div>
                <h4>{t('second-section.item-3-title')}</h4>
                <p>{t('second-section.item-3-subtitle')}</p>
              </div>
            </div>
            <div className='col-md-3 col-sm-6'>
              <div className='service-item'>
                <div className='icon'>
                  <img src='img/fourth-icon.png' alt='' />
                </div>
                <h4>{t('second-section.item-4-title')}</h4>
                <p>{t('second-section.item-4-subtitle')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='third-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-10 col-md-offset-1'>
              <div className='row'>
                <div className='left-image col-md-4'>
                  <img src='img/left-image.png' alt='' />
                </div>
                <div className='right-text col-md-8'>
                  <h4><em>{t('third-section.title')}</em><br />{t('third-section.subtitle')}</h4>
                  <p>{t('third-section.description')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='fourth-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-3 col-sm-6'>
              <div className='portfolio-item first-item'>
                <div className='image'>
                  <a href='img/01-big-item.jpg' data-lightbox='image-1'>
                    <img src='img/first-item.jpg' alt='' />
                  </a>
                </div>
                <div className='text'>
                  <span>{t('fourth-section.first-title')}</span>
                  <h4>{t('fourth-section.first-subtitle')}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-3 col-sm-6'>
              <div className='portfolio-item second-item'>
                <div className='image'>
                  <a href='img/02-big-item.jpg' data-lightbox='image-1'>
                    <img src='img/second-item.jpg' alt='' />
                  </a>
                </div>
                <div className='text'>
                  <span>{t('fourth-section.second-title')}</span>
                  <h4>{t('fourth-section.second-subtitle')}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-3 col-sm-6'>
              <div className='portfolio-item third-item'>
                <div className='image'>
                  <a href='img/03-big-item.jpg' data-lightbox='image-1'>
                    <img src='img/third-item.jpg' alt='' />
                  </a>
                </div>
                <div className='text'>
                  <span>{t('fourth-section.third-title')}</span>
                  <h4>{t('fourth-section.third-subtitle')}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-3 col-sm-6'>
              <div className='send-to-portfolio'>
                <span>{t('fourth-section.order')}</span>
                <div className='primary-button'>
                  <a href='/article/blog'>{t('fourth-section.view-blog')}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='fivth-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-10 col-md-offset-1'>
              <div className='row'>
                <div className='left-text col-md-8'>
                  <h4><em>{t('fivth-section.title')}</em><br />{t('fivth-section.subtitle')}</h4>
                  <p>{t('fivth-section.description')}</p>
                </div>
                <div className='right-image col-md-4'>
                  <img src='img/right-image.png' alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='sixth-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='row'>
                <div className='col-md-6'>
                  <fieldset>
                    <input
                      name='name'
                      type='text'
                      className='form-control'
                      id='name'
                      placeholder={t('sixth-section.name')}
                      required
                      value={props.name}
                      onChange={props.onChangeName}
                    />
                  </fieldset>
                </div>
                <div className='col-md-6'>
                  <fieldset>
                    <input
                      name='email'
                      type='email'
                      className='form-control'
                      id='email'
                      placeholder={t('sixth-section.email')}
                      required
                      value={props.email}
                      onChange={props.onChangeEmail}
                    />
                  </fieldset>
                </div>
                <div className='col-md-12'>
                  <fieldset>
                    <textarea
                      name='message'
                      rows={6}
                      className='form-control'
                      id='message'
                      placeholder={t('sixth-section.message')}
                      required
                      value={props.message}
                      onChange={props.onChangeMessage}
                    />
                  </fieldset>
                </div>
                <div className='col-md-12'>
                  <fieldset>
                    <button type='submit' id='form-submit' className='btn' onClick={props.onPressSendEmail}>
                      {t('sixth-section.send')}
                    </button>
                  </fieldset>
                </div>
              </div>
            </div>
            <div className='col-md-5'>
              <div className='right-info'>
                <ul>
                  <li><a href='/'><i className='fa fa-envelope' />ggunty96@gmail.com</a></li>
                  <li><a href='/'><i className='fa fa-phone' />-- --- --- ---</a></li>
                  <li><a href='/'><i className='fa fa-map-marker' />{t('sixth-section.address')}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <ul>
                <li><a href='/'><i className='fa fa-facebook' /></a></li>
                <li><a href='/'><i className='fa fa-twitter' /></a></li>
                <li><a href='/'><i className='fa fa-linkedin' /></a></li>
                <li><a href='/'><i className='fa fa-rss' /></a></li>
                <li><a href='/'><i className='fa fa-dribbble' /></a></li>
              </ul>
              <p>Copyright &copy; 2020 Gotha Guntter-Istvan PFA

                      | Design: Tooplate</p>
            </div>
          </div>
        </div>
      </footer>
    </Layout>
  );
}

export default Main;
