import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  // createArticle - function
  createArticle,
  hideCreateArticleError,
} from '../redux/actions';
import CreateArticle from './CreateArticle';
import i18n from '../utils/i18n';
import { ArticleType } from '../types';
import { RootState } from '../redux/store';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface CreateArticlePageProps extends PropsFromRedux, RouteComponentProps {}

interface CreateArticlePageState {
  article: ArticleType;
  password: string;
  lang: string;
  preview: boolean;
}

const INITIAL_STATE: CreateArticlePageState = {
  article: {
    id: '',
    title: '',
    content: '',
  },
  password: '',
  lang: 'en',
  preview: false,
}

class CreateArticlePage extends Component<CreateArticlePageProps, CreateArticlePageState> {
  state = INITIAL_STATE;

  createArticle = () => {
    const { article, lang, password } = this.state;
    const onSuccess = (id: string) => {
      i18n.changeLanguage(lang);
      this.setState(INITIAL_STATE, () => window.location.href = `/article/${id}`);
    };
    const onError = (errorMsg: string) => {
      alert(errorMsg);
      this.props.hideCreateArticleError();
    }
    this.props.createArticle({ article, lang, password }, onSuccess, onError);
  }

  render() {
    return (
      <CreateArticle
        article={this.state.article}
        setArticleId={(id: string) => this.setState({ article: { ...this.state.article, id } })}
        setArticleTitle={(title: string) => this.setState({ article: { ...this.state.article, title }})}
        setArticleContent={(content: string) => this.setState({ article: { ...this.state.article, content }})}
        createArticle={this.createArticle}
        loading={this.props.loadingCreateArticle}
        password={this.state.password}
        setPassword={(password: string) => this.setState({ password })}
        lang={this.state.lang}
        setLang={(lang: string) => this.setState({ lang })}
        preview={this.state.preview}
        togglePreview={() => this.setState(prevState => ({ preview: !prevState.preview }))}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const { loadingCreateArticle, hasErrorCreateArticle, errorMessageCreateArticle } = state.article;
  return { loadingCreateArticle, hasErrorCreateArticle, errorMessageCreateArticle };
};

const mapDispatch = {
  // createArticle
  createArticle,
  hideCreateArticleError,
};

const connector = connect(
  mapStateToProps,
  mapDispatch,
);

export default connector(CreateArticlePage);
