import React from 'react';
// @ts-ignore
import MathJax from 'react-mathjax3';

interface MathJaxContextProps {
  html: string;
}

const options = {
  messageStyle: 'none',
  extensions: ['tex2jax.js'],
  jax: ['input/TeX', 'output/HTML-CSS'],
  tex2jax: {
    inlineMath: [['$', '$'], ['\\(', '\\)']],
    displayMath: [['$$', '$$'], ['\\[', '\\]']],
    processEscapes: true,
  },
  TeX: {
    extensions: ['AMSmath.js', 'AMSsymbols.js', 'noErrors.js', 'noUndefined.js']
  }
};

const MathJaxContext: React.FC<MathJaxContextProps> = props => (
  <MathJax.Context
    input='tex'
    onLoad={() => console.log('Loaded MathJax script!')}
    onError={(MathJax: any, _error: any) => MathJax.Hub.Queue(MathJax.Hub.Typeset())}
    script='https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.0/MathJax.js'
    options={options}
  >
    <MathJax.Html html={props.html} />
  </MathJax.Context>
);

export { MathJaxContext };
