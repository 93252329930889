import React from 'react';
import { Layout, MathJaxContext } from '../common';
import { ArticleType } from '../types';

interface ArticleProps {
  onChangeLanguage: (lang: string) => void;
  loading: boolean;
  hasError: boolean;
  errorMessageGetArticle: string;
  hideError: () => void;
  article: ArticleType;
};

const Article: React.FC<ArticleProps> = props => (
  <Layout onChangeLanguage={props.onChangeLanguage}>
    <div className='mx-3 mt-2'>
      {props.loading &&
        <div className='d-flex justify-content-center mt-2'>
          <div className='spinner-border' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      }
      {props.hasError &&
        <div className='d-flex justify-content-center' style={{ color: 'red' }}>
          {props.errorMessageGetArticle}
        </div>
      }
      <div className='d-flex justify-content-center'>
        <MathJaxContext html={props.article.title} />
      </div>
      <MathJaxContext html={props.article.content} />
    </div>
  </Layout>
);

export default Article;
