import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { setI18nConfig } from './utils/i18n';
import Router from './Router';

interface AppProps {};

class App extends Component<AppProps> {
  constructor(props: AppProps) {
    super(props);
    setI18nConfig();
  }

  render() {
    return (
      <Provider store={store}>
        <Router />
      </Provider>
    );
  }
}

export default App;
